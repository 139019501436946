import React, { useEffect, useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import MovieDetail from "./MovieDetail";
import HouseDetail from "./HouseDetail";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Button from "@material-ui/core/Button";
import SendToWeb from "./SendToWeb";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import ExpandLess from "@material-ui/icons/ExpandLess";
import uniqueId from "lodash/uniqueId";
import Box from "@material-ui/core/Box";
import { BoxofficeRed, BoxofficeDeepRed } from "./CsStyles";
import { fetchData } from "../utils/fetchData.js";

const Showtimes = ({
  houseId = "",
  name,
  title,
  startdate,
  enddate,
  country,
  chainId = "",
  chainName,
  military,
  movieId,
  hasSearched,
  shouldSearch,
}) => {
  const [selectedMovie, setSelectedMovie] = useState(0);
  const [selectedHouse, setSelectedHouse] = useState(undefined);
  const [expandedHouses, setExpandedHouses] = useState([]);
  const [data, dispatch] = useReducer(
    (_, action) => {
      switch (action.type) {
        case "INSERT":
          return { rows: action.data, loading: false, error: null };
        case "LOADING":
          return { rows: [], loading: true, error: null };
        case "ERROR":
          return { rows: [], loading: false, error: action.error };
        default:
          throw Error("Unknown action.");
      }
    },
    { rows: [], loading: false, error: null },
  );

  useEffect(() => {
    if (!shouldSearch) {
      return;
    }
    dispatch({ type: "LOADING" });
    fetchData({
      endpoint: "schedule",
      body: {
        houseId,
        name,
        title,
        startdate,
        enddate,
        country,
        chainId,
        chainName,
        movieId,
      },
      handleComplete: (response) =>
        dispatch({ type: "INSERT", data: response.data }),
      handleError: (error) => dispatch({ type: "ERROR", error }),
      handleFinally: () => hasSearched(),
    });
  }, [
    houseId,
    name,
    title,
    startdate,
    enddate,
    country,
    chainId,
    chainName,
    shouldSearch,
  ]);

  if (data.loading) {
    return <CircularProgress></CircularProgress>;
  }

  if (data.error) {
    return (
      <div
        style={{ backgroundColor: BoxofficeRed, color: "white", padding: 12 }}
      >
        {data.error}
      </div>
    );
  }

  if (!data.loading && !data.rows.length) {
    return <div>No showtimes. Please choose a filter.</div>;
  }

  return (
    <div className="showtime-detail">
      <MovieDetail
        open={!!selectedMovie}
        movie_id={selectedMovie || 0}
        country={country}
        onClose={() => setSelectedMovie(0)}
        fullScreen={false}
      />

      <HouseDetail
        open={!!selectedHouse}
        house_id={selectedHouse}
        onClose={() => setSelectedHouse(undefined)}
        fullScreen={false}
      />

      {data.rows.map((house) => (
        <div key={`schedule-${house.id}`}>
          <Container disableGutters maxWidth={false}>
            <Container
              className="main-container"
              disableGutters
              maxWidth={false}
            >
              <Box bgcolor={BoxofficeRed} color="white" p={1} cursor="pointer">
                <div className={house.id} style={{ fontSize: 15 }}>
                  <b style={{ fontSize: 25, padding: 5 }}>
                    {house.name}
                    <IconButton
                      onClick={() => {
                        setSelectedHouse(house.id);
                      }}
                      align="right"
                      style={{ color: "white" }}
                    >
                      <InfoIcon />
                    </IconButton>
                    <SendToWeb houseId={house.id} color="white" />
                  </b>
                  <br></br>
                  <small style={{ padding: 5 }}>
                    {house.address.city}, {house.address.state},{" "}
                    {house.address.country} {house.address.zip}
                  </small>
                </div>
              </Box>
              <div style={{ marginTop: 5 }}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "white",
                    shadowRadius: 10,
                    fontSize: 12,
                  }}
                  startIcon={
                    expandedHouses.includes(house.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  }
                  onClick={() => {
                    if (expandedHouses.includes(house.id)) {
                      setExpandedHouses(
                        expandedHouses.filter((id) => id !== house.id),
                      );
                    } else {
                      setExpandedHouses([...expandedHouses, house.id]);
                    }
                  }}
                >
                  {expandedHouses.includes(house.id)
                    ? "HIDE MOVIES FROM THIS THEATRE"
                    : "SHOW ME ALL MOVIES AVAILABLE AT THIS THEATRE"}
                </Button>
              </div>
            </Container>
            {house.movies.map((movie, i) =>
              !expandedHouses.includes(house.id) && i >= 3 ? null : (
                <div
                  key={`schedule-${house.id}-${movie.id}`}
                  style={{ marginTop: 20 }}
                >
                  <div style={{ marginBottom: 15 }}>
                    <Divider />
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <b
                      onClick={() => setSelectedMovie(movie.id)}
                      style={{ cursor: "pointer", marginTop: 10, fontSize: 20 }}
                    >
                      {movie.name}
                    </b>
                    <small style={{ marginLeft: 5, fontSize: 10 }}>
                      ({movie.mpaa})
                    </small>
                  </div>
                  {movie.schedule.map((scheduleRow) => (
                    <div key={uniqueId("scheduleRow")}>
                      <div
                        style={{
                          marginBottom: 10,
                          fontSize: 15,
                          color: "grey",
                        }}
                      >
                        {scheduleRow.attributes.replaceAll(";", ", ")}
                      </div>
                      {scheduleRow.showdates.map((showdateRow) => (
                        <div key={uniqueId("showdate")}>
                          <strong>
                            {new Date(
                              `${showdateRow.showdate} EST`,
                            ).toLocaleDateString()}
                          </strong>
                          <Grid container spacing={1}>
                            {showdateRow.showtimes.map((showtime) => {
                              const goodLink = showtime.links.find(
                                (link) => link.attributesMatch,
                              );

                              let url = null;
                              let color = "secondary";
                              if (goodLink) {
                                color = "primary";
                                url = goodLink.url;
                              } else if (!!showtime.links.length) {
                                color = "warning";
                                url = showtime.links[0].url;
                              }
                              return (
                                <Grid key={uniqueId("showtime")} item>
                                  <Container
                                    disableGutters
                                    maxWidth="lg"
                                    style={{ marginRight: 5, marginTop: 10 }}
                                  >
                                    <div id={"hoverBase"}>
                                      <Button
                                        rel="noopener noreferrer"
                                        variant="contained"
                                        href={url}
                                        target="_blank"
                                        color={color}
                                        disabled={!url}
                                        style={
                                          url
                                            ? {
                                                fontSize: 15,
                                                backgroundColor:
                                                  BoxofficeDeepRed,
                                                color: "white",
                                              }
                                            : null
                                        }
                                      >
                                        {military
                                          ? showtime.sortTime
                                          : showtime.showtime}
                                      </Button>
                                    </div>
                                    <Typography
                                      sx={{ p: 1 }}
                                      id="hoverPopup"
                                      style={{ fontSize: 12 }}
                                    >
                                      {showtime.importedAt}
                                    </Typography>
                                  </Container>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ),
            )}
          </Container>
        </div>
      ))}
    </div>
  );
};

export default Showtimes;
