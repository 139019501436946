import { configState } from "../Config";

export const fetchData = ({
  endpoint,
  handleComplete,
  method = "POST",
  body = {},
  handleError = undefined,
  handleFinally = undefined,
}) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });

  return fetch(`${configState.apiBase}${endpoint}`, {
    method,
    body: formData,
  })
    .then((response) => response.json())
    .then((response) => {
      if ('error' in response && response.error && handleError) {
        handleError(response.error);
        return;
      }
      handleComplete(response);
    })
    .catch((error) => {
      if (handleError) {
        handleError(error ? error.message : "Unknown error");
      }
    })
    .finally(() => {
      if (handleFinally) {
        handleFinally();
      }
    });
};

