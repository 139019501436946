import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AutocompleteMovies from "../AutocompleteMovies";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../CsStyles";
import { CountryCodes } from "../../Constants";
import WhereIsTable from "./WhereIsTable";

const CURR_SEARCH_KEY = "CURR_SEARCH";

const Reports = ({ setTitle, classes }) => {
  const [movieId, setMovieId] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [country, setCountry] = useState("");
  const [imax, setImax] = useState(false);
  const [m3d, setM3d] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [movieTitle, setMovieTitle] = useState("");

  useEffect(() => {
    const currSearch = window.localStorage.getItem(CURR_SEARCH_KEY);
    let data;
    try {
      data = JSON.parse(currSearch);
    } catch {
      return;
    }
    if (!data || typeof data !== "object") {
      return;
    }
    setMovieId(data.movieId);
    setStartdate(data.startdate);
    setEnddate(data.enddate);
    setCountry(data.country);
    setImax(data.imax);
    setM3d(data.m3d);
  }, []);

  useEffect(() => {
    if (setTitle) {
      setTitle("Report");
    }
  }, [setTitle]);

  const enterPressed = (event) => {
    if ((event.keyCode || event.which) === 13) {
      fetchData();
    }
  };

  const fetchData = () => {
    setShouldSearch(true);
    window.localStorage.setItem(
      CURR_SEARCH_KEY,
      JSON.stringify({
        movieId,
        startdate,
        enddate,
        country,
        imax,
        m3d,
      }),
    );
  };

  const clearAll = () => {
    setMovieId("");
    setStartdate("");
    setEnddate("");
    setCountry("");
    setImax(false);
    setM3d(false);
    setShouldSearch(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Accordion
          defaultExpanded
          style={{
            width: "90%",
            borderRadius: 0,
            fontWeight: "bold",
            marginLeft: "50px",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="filter-header">
            <Typography>Filter</Typography>
          </AccordionSummary>

          <FormControl
            variant="standard"
            style={{
              width: "30%",
              marginTop: "16px",
              marginLeft: "16px",
            }}
          >
            <AutocompleteMovies
              handleSetId={(id, title) => {
                setMovieId(id);
                if (title) {
                  setMovieTitle(title);
                }
              }}
              parentOnly
            />
          </FormControl>

          <FormControl
            variant="standard"
            style={{
              width: "10%",
              marginTop: "16px",
              marginLeft: "16px",
            }}
          >
            <InputLabel id="label_form">Country</InputLabel>
            <Select
              labelId="country-label"
              id="country"
              label="Select Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <MenuItem value="" title="All">
                All
              </MenuItem>
              {Object.keys(CountryCodes).map((option) => (
                <MenuItem
                  key={option}
                  title={CountryCodes[option]}
                  value={option}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            id="startdate"
            label="Start Date"
            className={classes.textField}
            value={startdate}
            type="date"
            onChange={(e) => setStartdate(e.target.value)}
            onKeyPress={enterPressed}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            id="enddate"
            label="End Date"
            className={classes.textField}
            value={enddate}
            type="date"
            onChange={(e) => setEnddate(e.target.value)}
            onKeyPress={enterPressed}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            variant="outlined"
            onClick={fetchData}
            className={classes.button}
            style={{ padding: 10, marginTop: 15 }}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            onClick={clearAll}
            className={classes.button}
            style={{ padding: 10, marginTop: 15 }}
          >
            Clear
          </Button>
          <Grid style={{ marginLeft: "5px" }}>
            <FormControl className="Imax">
              <FormHelperText style={{ marginLeft: "16px" }}>
                IMAX
              </FormHelperText>
              <Switch
                checked={imax}
                label="imax"
                onChange={(_, checked) => setImax(checked)}
                color="primary"
              />
            </FormControl>
            <FormControl className="3D">
              <FormHelperText style={{ marginLeft: "25px" }}>3D</FormHelperText>
              <Switch
                checked={m3d}
                label="3D"
                onChange={(_, checked) => setM3d(checked)}
                color="primary"
              />
            </FormControl>
          </Grid>
        </Accordion>

        <Box className={classes.appBarSpacer} />
        <WhereIsTable
          movie_id={movieId}
          startdate={startdate}
          enddate={enddate}
          country={country}
          imax={imax}
          m3d={m3d}
          shouldSearch={shouldSearch}
          handleHasSearched={() => setShouldSearch(false)}
          title={movieTitle}
        />
      </Box>
    </React.Fragment>
  );
};

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reports);
