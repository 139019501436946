import React, { useEffect, useReducer } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { BoxofficeRed } from "../CsStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import isEqual from "lodash/isEqual";
import { Link } from "react-router-dom";
import { fetchData } from "../../utils/fetchData";

const tryGetValue = (data, key, defaultValue = "") => {
  if (data && key in data) {
    return data[key] || defaultValue;
  }
  return defaultValue;
};

const fillValues = (data, isNew = false) => ({
  id: !isNew ? tryGetValue(data, "id") : null,
  vendorname: tryGetValue(data, "vendorname", "New Vendor"),
  vendorid: tryGetValue(data, "vendorid"),
  vendorsite: tryGetValue(data, "vendorsite"),
  vendorcountries: tryGetValue(data, "vendorcountries"),
  chain_id: tryGetValue(data, "chain_id", 0),
  housepattern: tryGetValue(data, "housepattern"),
  linkpattern: tryGetValue(data, "linkpattern"),
  houseids: tryGetValue(data, "houseids"),
  vendorlogo: tryGetValue(data, "vendorlogo"),
  enabled: tryGetValue(data, "enabled", false),
  free_bucket: tryGetValue(data, "free_bucket", false),
});

const Form = ({ match, isNew = false, setTitle, history }) => {
  const [form, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "LOADING":
          return {
            ...state,
            loading: true,
            error: null,
          };
        case "FILL":
          return {
            loading: false,
            error: null,
            values: fillValues(action.data),
          };
        case "ERROR":
          return {
            ...state,
            loading: false,
            error: action.error,
          };
        case "SET_VALUE":
          return {
            ...state,
            values: {
              ...state.values,
              [action.key]: action.value,
            },
          };
        default:
          throw Error("Unknown action.");
      }
    },
    { values: fillValues(null, isNew), loading: false, error: null },
  );

  useEffect(() => {
    if (!match.params.id || isNew) {
      return;
    }
    dispatch({ type: "LOADING" });
    fetchData({
      endpoint: "vendors/get",
      body: { id: match.params.id },
      handleComplete: (response) => {
        dispatch({ type: "FILL", data: response.data });
      },
      handleError: (error) => dispatch({ type: "ERROR", error }),
    });
  }, [match.params.id, isNew]);

  useEffect(() => {
    setTitle(isNew ? "Create vendor" : "Edit vendor");
  }, [setTitle, isNew]);

  const handleSave = () => {
    dispatch({ type: "LOADING" });
    fetchData({
      endpoint: isNew ? "vendors/create" : "vendors/update",
      body: form.values,
      handleComplete: (response) => {
        if (isNew) {
          history.push(`/vendors/${response.data.id}`);
        } else {
          dispatch({ type: "FILL", data: response.data });
        }
      },
      handleError: (error) => dispatch({ type: "ERROR", error }),
    });
  };

  const setValue = (key, value) => dispatch({ type: "SET_VALUE", key, value });

  return (
    <div style={{ margin: "0 auto", maxWidth: "60rem" }}>
      {form.error && (
        <div
          style={{ backgroundColor: BoxofficeRed, color: "white", padding: 12 }}
        >
          {form.error}
        </div>
      )}

      <div
        style={{
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: `repeat(6,minmax(0, 1fr))`,
          gap: "1rem",

          padding: "1rem",
          width: "100%",
        }}
      >
        <FormControl style={{ gridColumn: "span 4" }}>
          <TextField
            label={"Name"}
            name="vendorname"
            type="text"
            value={form.values.vendorname}
            onChange={(e) => {
              setValue("vendorname", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"ID"}
            name="vendorid"
            type="text"
            value={form.values.vendorid}
            onChange={(e) => {
              setValue("vendorid", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 2" }}>
          <TextField
            label={"Countries"}
            name="vendorcountries"
            type="text"
            value={form.values.vendorcountries}
            onChange={(e) => {
              setValue("vendorcountries", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 4" }}>
          <TextField
            label={"Website"}
            name="vendorsite"
            type="text"
            value={form.values.vendorsite}
            onChange={(e) => {
              setValue("vendorsite", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"Chain ID"}
            name="chain_id"
            type="number"
            value={form.values.chain_id}
            onChange={(e) => {
              setValue("chain_id", Number(e.target.value));
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 3" }}>
          <TextField
            label={"House IDs"}
            name="houseids"
            type="text"
            value={form.values.houseids}
            onChange={(e) => {
              setValue("houseids", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: `span 6` }}>
          <TextField
            label={"House pattern"}
            name="housepattern"
            type="text"
            value={form.values.housepattern}
            onChange={(e) => {
              setValue("housepattern", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl style={{ gridColumn: "span 6" }}>
          <TextField
            label={"Link pattern"}
            name="linkpattern"
            type="text"
            value={form.values.linkpattern}
            onChange={(e) => {
              setValue("linkpattern", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>

        <FormControl
          style={{ gridColumn: `span ${!!form.values.vendorlogo ? "5" : "6"}` }}
        >
          <TextField
            label={"Logo"}
            name="vendorlogo"
            type="text"
            value={form.values.vendorlogo}
            onChange={(e) => {
              setValue("vendorlogo", e.target.value);
            }}
            margin="normal"
          />
        </FormControl>
        {!!form.values.vendorlogo ? (
          <div style={{ gridColumn: "span 1" }}>
            <img src={form.values.vendorlogo} style={{ width: "100%" }} />
          </div>
        ) : null}

        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Enabled
            <Checkbox
              checked={form.values.enabled}
              onChange={() => setValue("enabled", !form.values.enabled)}
              title="enabled"
            ></Checkbox>
          </FormLabel>
        </FormControl>

        <FormControl style={{ gridColumn: "span 1" }}>
          <FormLabel>
            Free Bucket
            <Checkbox
              checked={form.values.free_bucket}
              onChange={() => setValue("free_bucket", !form.values.run_once)}
              title="free_bucket"
            ></Checkbox>
          </FormLabel>
        </FormControl>
      </div>
      <div style={{ display: "flex", gap: "1rem" }}>
        {form.loading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={isEqual(fillValues({}), form.values)}
          >
            Save
          </Button>
        )}
        <Link to="/vendors" variant="contained" color="secondary">
          <Button variant="contained" color="secondary">
            Back
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Form;
