import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Token from "../components/Token";
import Showtimes from "../components/Showtimes";
import { styles } from "../components/CsStyles";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format, formatISO, addDays } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SearchBar from "../components/SearchBar/SearchBar";
import querystring from "querystring";

const ISO_DATE_FORMAT = "yyyy-MM-dd";

const DEFAULT_FORM_VALUES = {
  houseId: "",
  name: "",
  title: "",
  movieId: "",
  startdate: formatISO(new Date()),
  enddate: formatISO(addDays(new Date(), 7)),
  city: "",
  state: "",
  country: [],
  chainId: "",
  chainName: "",
  movieId: "",
  military: false,
};

const Schedule = ({ classes, match, location, setTitle }) => {
  const [shouldSearch, setShouldSearch] = useState(!!match.params.chainId);
  const [loadedMatchParams, setLoadedMatchParams] = useState(false);
  const [loadedQueryParams, setLoadedQueryParams] = useState(false);
  const [values, dispatchValues] = useReducer((state, action) => {
    switch (action.type) {
      case "CLEAR":
        return DEFAULT_FORM_VALUES;
      case "SET_VALUE":
        return {
          ...state,
          [action.key]: action.value,
        };
      case "HANDLE_SEARCH":
        const nextState = {
          ...state,
          houseId: action.data.houseId || DEFAULT_FORM_VALUES.houseId,
          name: action.data.name || DEFAULT_FORM_VALUES.name,
          chainName: action.data.chainName || DEFAULT_FORM_VALUES.chainName,
          chainId: action.data.chainId || DEFAULT_FORM_VALUES.chainId,
          altid: action.data.altid || DEFAULT_FORM_VALUES.altid,
          city: action.data.city || DEFAULT_FORM_VALUES.city,
          state: action.data.state || DEFAULT_FORM_VALUES.state,
          country: action.data.country || DEFAULT_FORM_VALUES.country,
        };
        if (action.data.main) {
          if (isNaN(action.data.main)) {
            nextState.name = action.data.main;
            nextState.houseId = "";
          } else {
            nextState.name = "";
            nextState.houseId = action.data.main;
          }
        }
        return nextState;
      default:
        throw Error("Unknown action.");
    }
  }, DEFAULT_FORM_VALUES);

  const setValue = (key, value) =>
    dispatchValues({ type: "SET_VALUE", key, value });
  const routeQuery = querystring.parse(location.search.replace("?", ""));

  useEffect(() => {
    setTitle("Schedules");
  }, [setTitle]);

  useEffect(() => {
    if (loadedMatchParams) {
      return;
    }
    Object.keys(match.params).forEach((key) => {
      if (
        !!match.params[key] &&
        key in values &&
        values[key] !== match.params[key]
      ) {
        setValue(key, match.params[key]);
      }
    });
    setLoadedMatchParams(true);
  }, [match, setValue, values, loadedMatchParams, setLoadedMatchParams]);

  useEffect(() => {
    if (loadedQueryParams) {
      return;
    }
    if (!routeQuery) {
      setLoadedQueryParams(true);
      return;
    }
    Object.keys(routeQuery).forEach((key) => {
      if (!!routeQuery[key] && key in values) {
        let value;
        switch (key) {
          case "country":
            value = Array.isArray(routeQuery[key])
              ? routeQuery[key]
              : [routeQuery[key]];
            break;
          case "startdate":
          case "enddate":
            value = formatISO(new Date(`${routeQuery[key]} EST`));
            break;
          default:
            value = routeQuery[key];
            break;
        }
        if (value !== values[key]) {
          setValue(key, value);
        }
      }
    });
    setLoadedQueryParams(true);
  }, [routeQuery, setValue, values, loadedQueryParams, setLoadedQueryParams]);

  return (
    <React.Fragment>
      <Token />
      <CssBaseline />
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Typography variant="h4">Search</Typography>

          {/* Form begins here */}
          <div className={classes.appBarSpacer} />

          <div className={classes.advancedSearch} noValidate autoComplete="off">
            <SearchBar
              match={match}
              onSave={(data) => () => {
                dispatchValues({ type: "HANDLE_SEARCH", data });
              }}
              searchCriteria={[
                ["Name", "name"],
                ["House Id", "houseId"],
                ["Chain Name", "chainName"],
                ["Chain Id", "chainId"],
                ["Alt Id", "altid"],
              ]}
              show_country
              show_state_and_city
            />
          </div>
          <form>
            <TextField
              id="title"
              autoFocus
              label="Movie Title"
              className={classes.textField}
              value={values.title}
              onChange={(event) => {
                setValue("title", event.target.value);
              }}
              margin="normal"
              InputProps={{
                style: {
                  padding: 2,
                },
              }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Start Date"
                value={values.startdate}
                disablePast
                onChange={(date) => {
                  const nextDate = formatISO(date);
                  setValue("startdate", nextDate);
                  if (nextDate > values.enddate) {
                    setValue("enddate", formatISO(addDays(date, 7)));
                  }
                }}
                margin="normal"
                className={classes.datePicker}
                clearable
              />
              <DatePicker
                label="End Date"
                value={values.enddate}
                onChange={(date) => {
                  setValue("enddate", formatISO(date));
                }}
                shouldDisableDate={(day) =>
                  formatISO(day, { representation: "date" }) <
                  formatISO(new Date(values.startdate), {
                    representation: "date",
                  })
                }
                margin="normal"
                className={classes.datePicker}
                clearable
              />
            </MuiPickersUtilsProvider>

            <Button
              onClick={() => setShouldSearch(true)}
              className={(classes.button, classes.datePicker)}
            >
              Search
            </Button>
            <Button
              onClick={() => dispatchValues({ type: "CLEAR" })}
              className={(classes.button, classes.datePicker)}
            >
              Clear
            </Button>
          </form>

          <FormControlLabel
            control={
              <Switch
                checked={values.mlitary}
                onChange={(_, checked) => {
                  setValue("military", checked);
                }}
                name="military"
                color="primary"
              />
            }
            label="24 Hour Format"
          />

          <Typography variant="h4" className={classes.sectionTitle}>
            Schedules
          </Typography>

          <div className={classes.contentflex}>
            <Showtimes
              shouldSearch={shouldSearch}
              hasSearched={() => setShouldSearch(false)}
              houseId={values.houseId}
              name={values.name}
              chainId={values.chainId}
              chainName={values.chainName}
              title={values.title}
              city={values.city}
              state={values.state}
              country={values.country}
              startdate={format(new Date(values.startdate), ISO_DATE_FORMAT)}
              enddate={format(new Date(values.enddate), ISO_DATE_FORMAT)}
              military={values.military}
              movieId={values.movieId}
            />
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

Schedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Schedule);
