import React, { useEffect, useReducer, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import EnableIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import orderBy from "lodash/orderBy";
import DownIcon from "@material-ui/icons/ArrowDownward";
import UpIcon from "@material-ui/icons/ArrowUpward";
import { Link } from "react-router-dom";
import { fetchData } from "../../utils/fetchData";
import Token from "../Token";
import { BoxofficeRed } from "../CsStyles";

const Vendors = ({ setTitle }) => {
  const [showDisabled, setShowDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortAsc, setSortAsc] = useState(true);
  const [vendors, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "LOADING":
          return {
            ...state,
            loading: true,
            error: null,
          };
        case "ERROR":
          return {
            ...state,
            loading: false,
            error: action.error,
          };
        case "SET_ALL":
          return {
            loading: false,
            error: null,
            data: action.vendors,
          };
        case "UPDATE":
          return {
            ...state,
            data: state.data.map((vendor) =>
              vendor.id === action.updateVendor.id
                ? action.updateVendor
                : vendor,
            ),
          };
        default:
          throw Error("Unknown action.");
      }
    },
    { data: [], loading: false, error: null },
  );

  useEffect(() => {
    setTitle("Vendors");
    dispatch({ type: "LOADING" });
    fetchData({
      endpoint: "vendors/list",
      handleComplete: (response) => {
        dispatch({ type: "SET_ALL", vendors: response.data });
      },
      handleError: (error) => dispatch({ type: "ERROR", error }),
    });
  }, []);

  const filteredVendors = vendors.data.filter((row) => {
    if (!showDisabled && !row.enabled) {
      return false;
    }
    if (search) {
      let found = false;
      for (const key in row) {
        if (typeof row[key] === "string") {
          if (row[key].toLowerCase().includes(search)) {
            found = true;
          }
        }
      }
      return found;
    }
    return true;
  });

  const handleSetSorting = (nextSortField) => {
    if (nextSortField !== sortField) {
      setSortField(nextSortField);
      setSortAsc(true);
    } else {
      setSortAsc(!sortAsc);
    }
  };

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "40rem",
        padding: "0 1rem",
        width: "100%",
      }}
    >
      <Token />
      <div
        style={{
          backgroundColor: "#fafafa",
          position: "sticky",
          top: "0",
          zIndex: 1,
        }}
      >
        {vendors.error && (
          <div
            style={{
              backgroundColor: BoxofficeRed,
              color: "white",
              padding: 12,
            }}
          >
            {vendors.error}
          </div>
        )}
        <div
          style={{
            alignItems: "baseline",
            display: "flex",
            gap: "1rem",
            justifyContent: "start",
            padding: "1rem 0",
            width: "100%",
          }}
        >
          <FormControl>
            <TextField
              label={"Search"}
              name="search"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              margin="normal"
            />
          </FormControl>

          <FormLabel>
            Show disabled
            <Checkbox
              checked={showDisabled}
              onChange={() => setShowDisabled(!showDisabled)}
              title="Show disabled"
            ></Checkbox>
          </FormLabel>
          <FormControl>
            <Link to="/vendors/create">
              <Button color="primary" variant="contained">
                Add new vendor
              </Button>
            </Link>
          </FormControl>
        </div>
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid #666",
            display: "grid",
            gridTemplateColumns: "9fr 1fr 1fr",
            gridTemplateRows: "3.125rem",
            gap: "1rem",
            padding: "0.5rem 0",
            position: "sticky",
            top: 0,
          }}
        >
          <div
            onClick={() => handleSetSorting("vendorname")}
            style={{ alignItems: "center", cursor: "pointer", display: "flex" }}
            role="button"
          >
            Name{" "}
            {sortField === "vendorname" ? (
              sortAsc ? (
                <DownIcon />
              ) : (
                <UpIcon />
              )
            ) : null}
          </div>
        </div>
      </div>
      {vendors.loading ? (
        <CircularProgress />
      ) : (
        orderBy(
          filteredVendors,
          [(o) => o.vendorname.toLowerCase()],
          [sortAsc ? "asc" : "desc"],
        ).map((vendor) => (
          <div
            key={`vendor-${vendor.id}`}
            style={{
              alignItems: "center",
              borderBottom: "1px solid #666",
              display: "grid",
              gridTemplateColumns: "9fr 1fr 1fr",
              gridTemplateRows: "3.125rem",
              gap: "1rem",
              padding: "0.5rem 0",
            }}
          >
            <strong style={{ fontSize: "1rem" }}>{vendor.vendorname}</strong>

            <Tooltip placement="top" title="Edit">
              <Link to={`/vendors/${vendor.id}`}>
                {" "}
                <IconButton color="secondary">
                  <EditIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip
              placement="top"
              title={vendor.enabled ? "Enabled" : "Disabled"}
            >
              <IconButton
                onClick={() =>
                  fetchData({
                    endpoint: "vendors/toggle",
                    body: { id: vendor.id },
                    handleComplete: (response) => {
                      dispatch({ type: "UPDATE", updateVendor: response.data });
                    },
                  })
                }
                color="primary"
              >
                {vendor.enabled ? (
                  <EnableIcon htmlColor="green" />
                ) : (
                  <CancelIcon color="error" />
                )}
              </IconButton>
            </Tooltip>
          </div>
        ))
      )}
    </div>
  );
};

export default Vendors;
